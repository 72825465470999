<template>
  <div class="home">
    <div class="banner">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in sliderData" :key="index">
          <img :src="item.imgUrl" alt="" class="bannerImg">
        </van-swipe-item>
      </van-swipe>
    </div>
    <ul class="ulWrapper">
      <li v-for="(item, index) in homeList" :key="index" class="listWrapper" @click="goPayOrder(item.id)">
        <div class="list-item">
          <img :src="item.imgUrl" alt="" class="listImg">
          <p class="listTitle">{{ item.goodsName }}</p>
          <p class="listBottom" v-if="item.price !== '0.00'">
            <span class="price">￥{{ item.price }}</span>
            <!-- <span class="sales">销量:{{ item.sales }}</span> -->
          </p>
        </div>
      </li>
    </ul>
    <!-- <div class="toOrderList" @click="queryOrderList">
      <i class="iconfont icon">&#xe603;</i>
      <p class="orderTitle">订单</p>
    </div> -->
    <van-dialog v-model="show" title="订单查询" show-cancel-button @confirm="confirmClick" @cancel="cancelClick">
      <van-field v-model="phoneNumber" placeholder="请输入手机号" />
    </van-dialog>
  </div>
</template>

<script>
import { getHomeData, getServiceData } from 'api/api'

export default {
  name: 'home',
  props: {
    id: {
      require: true
    }
  },
  data () {
    return {
      homeList: [],
      show: false,
      phoneNumber: '',
      sliderData: []
    }
  },
  created () {
    this._getHomeData()
  },
  methods: {
    _getHomeData () {
      this.$store.commit('setUserId', this.$route.params.id)
      this.$store.commit('setUrl', window.location.href.split('/home')[0])
      let params = {
        userId: this.$route.params.id
      }
      getHomeData(params).then(res => {
        if (res && res.code === 200) {
          console.log('主页数据', res)
          this.homeList = res.result.goodsList
          this.sliderData = res.result.sliderList
        }
      })
    },
    // 跳转商品页
    goPayOrder (id) {
      this.$router.push({
        path: `/placeOrder/${id}`,
        // name: 'placeAnOrder',
        // params: {
        //   id
        // }
      })
    },
    queryOrderList () {
      this.show = true
    },
    // 订单查询按钮 确认
    confirmClick () {
      if (!(/^1[3456789]\d{9}$/.test(this.phoneNumber))) {
        return this.$toast('请输入正确的手机号')
      }
      this.$router.push({
        name: 'order',
        params: {
          phone: this.phoneNumber
        }
      })
    },
    // 订单查询按钮 取消
    cancelClick () {
      this.show = false
    }
  }
}
</script>

<style lang="stylus" scoped>
  .home
    min-height 100%
    margin 11px 5px
    .banner
      // height 173px
      .bannerImg
        width 100%
        height 173px
    .ulWrapper
      margin-top 5px
      padding-bottom 30px
      display flex
      flex-wrap wrap
      .listWrapper
        width 50%
        // flex 40%
        // height 240px
        // background-color red
        margin-top 10px
        // &:nth-child(2n)
        //   margin-left 10px
        // &:last-child
        //   margin-left 0
        .list-item
          padding 0 5px
          box-sizing border-box
          .listImg
            width 100%
            height 172px
          .listTitle
            font-size 13px
            color #000
            font-weight 500
            margin-bottom 13px
          .listBottom
            display flex
            // justify-content space-between
            flex-direction row-reverse
            .price
              font-size 14px
              font-weight bold
              color #fa5757
            .sales
              font-size 12px
              font-weight 500
              color #666
    .toOrderList
      width 41px
      height 41px
      background-color #191D3D
      border-radius 50%
      position fixed
      bottom 20%
      right 11px
      color #fff
      text-align center
      line-height 25px
      .icon
        color #fff
      .orderTitle
        font-size 8px
        font-weight 500
        line-height 8px
</style>